<template>
  <div
    class="tw-bg-main d-flex justify-content-center align-items-center"
    style="height: 100vh"
  >
    <p class="p-3 alert-danger d-inline-block fw-bolder">
      (Fb)請稍後~正在為您轉址中...
    </p>
  </div>
</template>

<script>
// api
import { fbLogin } from '@/methods/API/fbApi'

export default {
  data() {
    return {
      identity: 'customer',
      code: null,
      state: null,
    }
  },
  created() {
    this.identity = window.localStorage.getItem('identity') ? window.localStorage.getItem('identity') : 'customer'
    this.checkIfYouCanEnter()
  },
  unmounted() {
    window.localStorage.removeItem('loginWithoutRegistration')
  },
  methods: {
    // 檢查是否可以進入
    checkIfYouCanEnter() {
      if (!this.$route.query.code) {
        alert('請按正常流程進入 ^^')
        if (this.identity === 'seller') return this.$router.push('/sellerLogin')
        else return this.$router.push('/customerLogin')
      }
      this.code = this.$route.query.code.split('#')[0]
      this.state = this.$route.query.state
      console.log(this.$route)
      this.fbLogin()
    },
    // Fb 登入
    fbLogin() {
      let state = this.state
      if (this.state === 'authorization') state = this.$methods.getCookie('serverToken')
      const fbLoginRes = fbLogin(this.code, state)
      fbLoginRes.then(res => {
        console.log(res)
        if (res.code === '200') {
          const token = res.data
          this.next(token)
        }
      }).catch(err => {
        console.log(err)
        if (err.responseJSON.code === '400-14') {
          this.SweetAlert('other', err.responseJSON.message)
          if (this.state === 'login') {
            // FB 登入
            if (this.identity === 'seller') return this.$router.push('/sellerLogin')
            else {
              window.localStorage.setItem('identity', "customer")
              return this.$router.push('/customerLogin')
            }
          } else if (this.state === 'authorization') {
            // FB 關聯
            if (this.identity === 'seller') return this.$router.push({name: 'S_LinkAccounts'})
            else return this.$router.push({name: 'C_LinkAccounts'})
          } else if (window.localStorage.getItem('loginWithoutRegistration')) {
            // FB 免註冊頁面
            this.$router.push('/authFb')
          }
        } else {
          this.SweetAlert('other', err.responseJSON.message)
        }
      })
    },
    next(token) {
      switch (this.state) {
        case "login":
          this.$methods.delCookie('serverToken')
          this.$methods.setCookie('serverToken', token)
          if (this.identity === "customer" || this.identity === "seller") this.go()
          // 使用者透過個人賣場 > fb 授權連結點進來的
          else {
            const storeId = this.identity.split(',')[1].split(':')[1]
            window.localStorage.setItem('identity', "customer")
            this.$router.push({
              name: "C_Shop",
              params: {
                storeId: storeId
              }
            })
          }
          break;
        case "authorization":
          if (this.identity === 'seller') return this.$router.push({name: "S_LinkAccounts"})
          else return this.$router.push({name: "C_LinkAccounts"})
          break;
      }
    },
    go() {
      this.$router.push(this.$methods.go('normal'))
    },
  },
}
</script>